import { type ReactNode } from "react";
import tw, { theme } from "twin.macro";
import { Link } from "react-router-dom";
import type { Placement } from "@floating-ui/react";

import IconLogout from "@iconsV2/Interrface/log-out.svg?react";
import IconChevronLeft from "@iconsV2/Arrows/chevron-left-normal.svg?react";

import Avatar from "@atoms/Avatar";
import Button from "@atoms/Button";
import Popover, { PopoverContent, PopoverTrigger } from "@atoms/Popover";
import ButtonText from "@atoms/ButtonText";
import Label from "@atoms/Label";
import CategoryButton from "@atoms/CategoryButton";
import { useMediaQuery } from "@/hooks/useMediaQuery";

import ConfirmLogout from "../ConfirmLogout";
import useProfileMenu from "./useProfileMenu";
import {
  StyledWrapperFitpointList,
  StyledWrapperItemsMenu,
  StyledWrapperItemsMenuButton,
  StyledWrapperProfile,
} from "./styles";
import FitpointCard from "../FitpointCard";

interface IProfileButtonProps {
  nameUser: string;
  children: ReactNode;
  openMenu: boolean;
  handleOpenMenu: (open: boolean) => void;
  placement?: Placement;
  onCloseLogout?: () => void; // To close HeaderMenu when logout modal close
  [key: string]: any;
}

const ProfileMenu = ({
  nameUser,
  children,
  placement = "bottom-end",
  openMenu,
  handleOpenMenu,
  onCloseLogout,
}: IProfileButtonProps) => {
  const isMedium = useMediaQuery("( min-width: {medium} )");
  const isLarge = useMediaQuery("( min-width: {large} )");
  const {
    // values
    logoutModalOpen,
    menuData,
    // func
    openLogoutModal,
    closeLogoutModal,
  } = useProfileMenu({ handleOpenMenu });

  return (
    <>
      <Popover
        open={openMenu}
        onOpenChange={handleOpenMenu}
        placement={placement}
        flip={false}
        fullScreen={!isMedium}
      >
        <PopoverTrigger>{children}</PopoverTrigger>

        <PopoverContent
          classes={{
            root: tw`bg-neutral-99
            medium:(w-60 !left-0 px-0 py-2 flex flex-col)
            large:(w-64)
            `,
          }}
        >
          <div tw="w-full px-1 py-2 flex medium:(hidden)">
            <Button
              sizeVariant="lg"
              onClick={() => handleOpenMenu(false)}
              color="secondary"
              variant="text"
              startIcon={<IconChevronLeft />}
              type="button"
            >
              Regresar
            </Button>
          </div>

          <StyledWrapperProfile as={Link} to={isLarge ? "/profile/personal-data" : "/profile"}>
            <Avatar label={nameUser} size="large" />

            <div tw="flex flex-col gap-xs">
              <Label sizeVariant="md" tw="line-clamp-1">
                {nameUser}
              </Label>
              <ButtonText sizeVariant={isMedium ? "sm" : "md"} tw="text-secondary-50">
                Ver perfil
              </ButtonText>
            </div>
          </StyledWrapperProfile>

          <div tw="w-full my-s px-0 medium:(px-sm) large:(px-m)">
            <FitpointCard />
          </div>

          <StyledWrapperFitpointList>
            <StyledWrapperItemsMenu>
              {menuData.map(({ Icon, title, to, external }, index) => (
                <CategoryButton
                  as={Link}
                  key={index}
                  to={to}
                  target={external ? "_blank" : ""}
                  rel={external ? "noopener noreferrer" : ""}
                  title={`Ver ${title}`}
                  color="secondary"
                  rounded={false}
                  fullWidth
                  startIcon={<Icon />}
                  classes={{
                    root: tw`py-ml gap-s medium:(py-s pt-m gap-xs last:pb-m)`,
                  }}
                >
                  <ButtonText sizeVariant={isMedium ? "sm" : "md"} color={theme`colors.secondary.40`}>
                    {title}
                  </ButtonText>
                </CategoryButton>
              ))}
            </StyledWrapperItemsMenu>
          </StyledWrapperFitpointList>

          <div tw="flex-1 flex items-end w-full">
            <StyledWrapperItemsMenuButton tw="w-full">
              <CategoryButton
                onClick={openLogoutModal}
                color="secondary"
                rounded={false}
                fullWidth
                startIcon={<IconLogout color={theme`colors.error.50`} />}
                classes={{
                  root: tw`w-full px-ml py-m gap-s medium:(gap-xs)`,
                }}
              >
                <ButtonText sizeVariant={isMedium ? "sm" : "md"} color={theme`colors.error.50`}>
                  Cerrar Sesión
                </ButtonText>
              </CategoryButton>
            </StyledWrapperItemsMenuButton>
          </div>
        </PopoverContent>
      </Popover>
      {/* modal ConfirmLogout */}

      {logoutModalOpen && (
        <ConfirmLogout isOpen={logoutModalOpen} handleClose={closeLogoutModal} onLogout={onCloseLogout} />
      )}
    </>
  );
};

export default ProfileMenu;
