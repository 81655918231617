import { useState, useEffect, useMemo } from "react";
import { type Placement } from "@floating-ui/react";
import tw from "twin.macro";
import { Link, useLocation } from "react-router-dom";
import { useQueryCache } from "react-query";
import { useQuery } from "@tanstack/react-query";
import { useLazyQuery } from "@apollo/client";
import Ciity from "@atoms/Ciity";
import CategoryButton from "@atoms/CategoryButton";
import Button from "@atoms/Button";
import Heading from "@atoms/Heading";
import PopoverButton from "@/components/Atoms/PopoverButton";
import Popover, { PopoverContent, PopoverTrigger } from "@atoms/Popover";
import HeaderMenu from "@/components/Molecules/HeaderMenu";
import InputSearch from "@molecules/InputSearch";
import ProfileButton from "@molecules/ProfileButton";
import NotificationsHeader from "@organisms/NotificationsHeader";
import useSessionContext from "@/hooks/useSesionContext";
import useAppDispatch from "@/hooks/useAppDispatch";
import useAppSelector from "@/hooks/useAppSelector";
import IconChevronLeft from "@iconsV2/Arrows/chevron-left-normal.svg?react";
import { getAvaliableStores } from "@/services/stores";
import { cartStoresActions } from "@/store/cartStore";
import { GET_CATEGORIES_HEADER } from "@/graphql/categories";
import { GET_HUBS } from "@/graphql/hubs";
import type { IHub } from "@/types/hubs";
import useModalAuth from "@/hooks/useModalAuth";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { userAction } from "@/store/user";
import ShoppingCart from "../ShoppingCart";
import type { CategoryNodeHeader, QueryResultCategoriesHubs } from "./types";
import useAddressModal from "@/hooks/useAddressModal";
import {
  StyledLogoFithubPrimary,
  StyledRootHeader,
  StyledContainer,
  StyledHeaderHead,
  StyledHeaderBody,
  StyledHeaderHeadRight,
  StyledHeaderHeadLeft,
  StyledIconChevronDown,
  StyledWrapperPopoverButtons,
} from "./styles";
import { type TSizeVariant } from "@/types/common";
import useElementOnScreen from "@/hooks/useElementOnScreen";

const LinksPersonalizeds = ({
  onClick,
  sizeVariant = "md",
}: {
  onClick?: () => void;
  sizeVariant?: TSizeVariant;
}) => (
  <>
    <CategoryButton
      label="Nuestra marca"
      color="secondary"
      as={Link}
      to="/c/nuestra-marca"
      onClick={onClick}
      sizeVariant={sizeVariant}
    />
    <CategoryButton
      sizeVariant={sizeVariant}
      label="Promofit"
      color="secondary"
      as={Link}
      to="/c/promofit"
      onClick={onClick}
    />
    <CategoryButton
      sizeVariant={sizeVariant}
      label="Nuevos"
      color="secondary"
      as={Link}
      to="/c/nuevos"
      onClick={onClick}
    />
  </>
);

const Header = () => {
  const { pathname } = useLocation();

  const [openMenuSearch, setOpenMenuSearch] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [openHamburguerMenu, setOpenHamburguerMenu] = useState(false);

  const isMin480 = useMediaQuery("( min-width: 410px )");
  const showAvatarWithName = useMediaQuery("( min-width: 780px )");
  const showLinksCollapsedMedium = useMediaQuery("( min-width: 1024px )");
  const isMedium = useMediaQuery("( min-width: {medium} )");
  const isLarge = useMediaQuery("( min-width: {large} )");
  const dispatch = useAppDispatch();

  const { setUserToken } = useSessionContext();
  const queryCache = useQueryCache();

  const { handleOpenModalAuth } = useModalAuth();

  const location = useAppSelector((state) => state.cart.location);
  const cartState = useAppSelector((state) => state.cart);
  const user = useAppSelector((state) => state.user);

  const { isVisible: isVisibleHeader } = useElementOnScreen({
    id: "header-container-to-collapse",
    callback: (show) => {
      // Close categories menu when change header
      setOpenMenu(false);
      if (show) {
        // Close header menu when change to normal header
        setOpenHamburguerMenu(false);
      }
    },
  });

  const isScrolled = !isVisibleHeader;

  const { isOpen: isAddressModalOpen, toggleAddressModal: setIsAddressModalOpen } = useAddressModal();

  const [dataMenuCategories, setDataMenuCategories] = useState<{
    categories: CategoryNodeHeader[];
    hubs: IHub[];
  }>({
    categories: [],
    hubs: [],
  });
  const popUpsReminder = useAppSelector((state) => state.popUpsReminder.showLocationModal);

  const isAddressActive = !!location?.lat && !!location?.lng && (!!location?.city || !!location?.address);

  useEffect(() => {
    if (popUpsReminder) {
      setIsAddressModalOpen(true);
    }
  }, [popUpsReminder]);

  const handleOpenMenu = (open: boolean) => {
    setOpenMenu(open);
  };

  const handleOpenHamburguerMenu = (open: boolean) => {
    setOpenHamburguerMenu(open);
  };

  const getUserLabel = (noFullName = false) => {
    if (user?.firstName && user?.lastName && !noFullName) {
      return `${user.firstName} ${user.lastName}`;
    }
    if (user?.firstName) {
      return user.firstName;
    }
    return user?.email ?? "";
  };

  const [getCategoriesHeader] = useLazyQuery<QueryResultCategoriesHubs>(GET_CATEGORIES_HEADER, {
    variables: {
      storeId: String(cartState.storeId),
    },
    onCompleted(data) {
      const ignoreFiletr = ["promofit", "nuevos", "nuestra-marca"];
      const { categories } = data;
      const formatedCategories = categories.edges
        .filter((item) => !ignoreFiletr.includes(item.node.slug))
        .map((item) => ({
          name: item.node.name,
          slug: item.node.slug,
        }));

      setDataMenuCategories((prev) => ({
        ...prev,
        categories: formatedCategories,
      }));
    },
  });

  const [getHubs] = useLazyQuery(GET_HUBS, {
    variables: {
      storeId: String(cartState.storeId),
    },
    onCompleted(data) {
      const { hubs } = data;

      const formatedHubs = hubs.edges.map((item) => ({
        ...item.node,
      }));

      setDataMenuCategories((prev) => ({
        ...prev,
        hubs: formatedHubs,
      }));
    },
  });

  const {
    status,
    data: dataAvailableStores,
    refetch,
    error,
  } = useQuery({
    queryKey: ["availableStores", location?.lat, location?.lng],
    queryFn: async () => await getAvaliableStores("", location?.lat, location?.lng),
    enabled: !!location?.lat && !!location?.lng,
  });

  const categoriesByBreakpoint = useMemo(() => {
    if (isLarge) {
      return dataMenuCategories.categories.slice(0, 3);
    }

    // if (isMedium) {
    //   return dataMenuCategories.categories.slice(0, 2);
    // }

    return [];
  }, [JSON.stringify(dataMenuCategories.categories), isLarge]); // Add breakpoints to calculate again in resize

  useEffect(() => {
    if (status === "success") {
      dispatch(
        cartStoresActions.setAvailableStores({
          availableStores: dataAvailableStores,
          validateAt: new Date().getTime(),
        })
      );

      if (!cartState.storeId) {
        dispatch(cartStoresActions.setStoreId(dataAvailableStores?.stores?.[0]?.id));
      } else if (cartState.storeId !== dataAvailableStores?.stores?.[0]?.id) {
        setIsAddressModalOpen(true);
      }
    }

    if (status === "error") {
      if (
        (error as any)?.response &&
        ((error as any).response.status === 401 || (error as any).response.status === 403)
      ) {
        setUserToken(null);
        dispatch(userAction.emptyUser());
        dispatch(cartStoresActions.emptyCart());
        dispatch(cartStoresActions.emptyAvailability());
      }

      void refetch();
      dispatch(cartStoresActions.emptyLocation());
    }
  }, [status, dataAvailableStores, cartState.storeId, error]);

  useEffect(() => {
    if (cartState.storeId) {
      void getCategoriesHeader();
      void getHubs();
    }
  }, [cartState.storeId]);

  useEffect(() => {
    if (!isAddressActive) {
      dispatch(cartStoresActions.setInitialLocation());
      void queryCache.refetchQueries("stores");
    }
  }, [isAddressActive]);

  const InputComponent = (
    <InputSearch
      itemHubs={dataMenuCategories.hubs}
      itemCategories={dataMenuCategories.categories.map((item) => ({
        slug: `/c/${item.slug}`,
        name: item.name,
      }))}
      openMenuSearchExternal={openMenuSearch}
      handleOpenMenuSearchExternal={setOpenMenuSearch}
      isScrolled={isScrolled}
    />
  );

  const MoreCategoriesButton = ({ placement = "bottom-start" }: { placement?: Placement }) => (
    <Popover
      open={openMenu}
      onOpenChange={isMedium ? handleOpenMenu : undefined}
      placement={placement}
      shift={false}
      flip={false}
      fullScreen={!isMedium}
    >
      <PopoverTrigger>
        <CategoryButton
          active={openMenu}
          endIcon={<StyledIconChevronDown $open={openMenu} />}
          label="Categorías"
          color="secondary"
          as="button"
          title="Categorías"
          onClick={() => handleOpenMenu(!openMenu)}
          classes={{
            root: tw`!gap-0`,
          }}
          sizeVariant={isMedium ? "sm" : "md"}
        />
      </PopoverTrigger>

      {dataMenuCategories.categories && dataMenuCategories.categories?.length >= 1 && (
        <PopoverContent classes={{ root: tw`gap-2 flex flex-col` }}>
          {!isMedium && (
            <div tw="w-full px-2 py-2 flex bg-neutral-99">
              <Button
                sizeVariant="lg"
                onClick={() => handleOpenMenu(false)}
                color="secondary"
                variant="text"
                startIcon={<IconChevronLeft />}
                type="button"
              >
                Regresar
              </Button>
            </div>
          )}

          <StyledWrapperPopoverButtons>
            {!isMedium && (
              <>
                <Heading type="h6">Categorías</Heading>

                <LinksPersonalizeds
                  onClick={() => handleOpenMenu(false)}
                  sizeVariant={isMedium ? "sm" : "md"}
                />
              </>
            )}

            {dataMenuCategories.categories?.map((item, index) => {
              const isActive = pathname === item.slug;

              if (isMedium) {
                return (
                  <PopoverButton
                    className={`category-list-item ${isActive ? "category-list-item-active" : ""}`}
                    as={Link}
                    key={index}
                    fullWidth
                    label={item.name}
                    to={`/c/${item.slug}`}
                    onClick={() => handleOpenMenu(false)}
                  />
                );
              }

              return (
                <CategoryButton
                  as={Link}
                  key={index}
                  label={item.name}
                  to={`/c/${item.slug}`}
                  onClick={() => handleOpenMenu(false)}
                  color="secondary"
                  sizeVariant={isMedium ? "sm" : "md"}
                />
              );
            })}
          </StyledWrapperPopoverButtons>
        </PopoverContent>
      )}
    </Popover>
  );

  return (
    <>
      <NotificationsHeader />

      <StyledRootHeader $isScrolled={isScrolled}>
        <StyledContainer>
          <StyledHeaderHead>
            <StyledHeaderHeadLeft $isScrolled={isScrolled}>
              {(!isLarge || (isLarge && isScrolled)) && (
                <HeaderMenu
                  open={openHamburguerMenu}
                  handleOpen={handleOpenHamburguerMenu}
                  isScrolled={isScrolled}
                />
              )}

              {!isScrolled && (
                <Link to="/" title="Fithub">
                  <StyledLogoFithubPrimary />
                </Link>
              )}

              {isLarge && !isScrolled && (
                <div tw="flex items-center">
                  {/* <CategoryButton
                    label="Tarjeta regalo"
                    color="secondary"
                    as={Link}
                    title="Canjea tu regalo"
                    to="/gift-cards/redeem"
                  /> */}
                  <CategoryButton
                    label="Canjea tu regalo"
                    color="secondary"
                    as={Link}
                    title="Canjea tu regalo"
                    to="/gift-cards/redeem"
                    sizeVariant={isMedium ? "sm" : "md"}
                  />
                  <CategoryButton
                    label="Blog"
                    color="secondary"
                    as={Link}
                    to={"https://blog.fithub.com.co"}
                    title={`Blog de Fithub`}
                    target="_blank"
                    rel="noopener noreferrer"
                    sizeVariant={isMedium ? "sm" : "md"}
                  />
                </div>
              )}

              {isMedium && isScrolled && (
                <div tw="flex items-center">
                  {showLinksCollapsedMedium && <LinksPersonalizeds sizeVariant={isMedium ? "sm" : "md"} />}

                  <MoreCategoriesButton placement="bottom-start" />
                </div>
              )}
            </StyledHeaderHeadLeft>

            {!isMedium && isScrolled && <div tw="w-full flex-1">{InputComponent}</div>}

            <StyledHeaderHeadRight $isScrolled={isScrolled}>
              {isMedium && InputComponent}

              {!isScrolled && (
                <ProfileButton
                  label={getUserLabel()}
                  firstName={showAvatarWithName || !isMedium ? getUserLabel(true) : undefined}
                  onClickLogin={() => handleOpenModalAuth({ initialStep: "login" })} // This function receives a parameter
                />
              )}
              <ShoppingCart isScrolled={isScrolled} />
            </StyledHeaderHeadRight>
          </StyledHeaderHead>

          {!isScrolled && !isMedium && <StyledHeaderBody>{InputComponent}</StyledHeaderBody>}
        </StyledContainer>

        {!isMedium && isScrolled && (
          <StyledContainer tw="flex-row items-center justify-between gap-0">
            {isMin480 ? (
              <div tw="flex items-center">
                <LinksPersonalizeds sizeVariant={isMedium ? "sm" : "md"} />
              </div>
            ) : (
              <div tw="flex items-center">
                <CategoryButton
                  label="Nuestra marca"
                  color="secondary"
                  as={Link}
                  to="/c/nuestra-marca"
                  sizeVariant={isMedium ? "sm" : "md"}
                />
                <CategoryButton
                  sizeVariant={isMedium ? "sm" : "md"}
                  label="Promofit"
                  color="secondary"
                  as={Link}
                  to="/c/promofit"
                />
              </div>
            )}

            <MoreCategoriesButton placement="bottom-end" />
          </StyledContainer>
        )}
      </StyledRootHeader>

      <div tw="w-full pt-xxs pb-s  medium:(border-neutral-90 border-t)" id="header-container-to-collapse">
        <StyledContainer>
          <div tw="w-full flex items-center justify-between">
            <Ciity open={isAddressModalOpen} handleChangeOpen={setIsAddressModalOpen} />

            {isMedium ? (
              <div tw="w-full flex items-center justify-end">
                <LinksPersonalizeds sizeVariant={isMedium ? "sm" : "md"} />

                {isLarge &&
                  categoriesByBreakpoint.map((item) => (
                    <Link to={`/c/${item.slug}`} key={item.slug} tw="w-auto">
                      <CategoryButton
                        label={item.name}
                        color="secondary"
                        title={item.name}
                        sizeVariant={isMedium ? "sm" : "md"}
                      />
                    </Link>
                  ))}

                <MoreCategoriesButton placement="bottom-end" />
              </div>
            ) : (
              <>
                <div css={[openMenu && !isMedium && tw`z-[53] fixed top-0 pointer-events-none opacity-0 `]}>
                  <MoreCategoriesButton placement="bottom-start" />
                </div>
              </>
            )}
          </div>
        </StyledContainer>
      </div>
    </>
  );
};

export default Header;
