import { useMemo } from "react";
import tw from "twin.macro";
import dayjs from "dayjs";
import { Link } from "react-router-dom";

import Chip from "@/components/Atoms/Chip";
import NewLetter from "@/components/Molecules/NewLetter";
import Button from "@/components/Atoms/Button";

import IconPinLocation from "@iconsV2/Interrface/pin-location.svg?react";
import IconClock from "@iconsV2/Calendar/clock.svg?react";
import IconCombinator from "@iconsV2/Backed/combinator.svg?react";
import IconIndustria from "@iconsV2/Backed/industria.svg?react";
import IconBrandWhite from "@iconsV2/Brand/white.svg?react";
import IconFlagColombia from "@iconsV2/Flags/colombia.svg?react";
import IconInstagramWhite from "@iconsV2/SocialIcon/instagram-white.svg?react";
import IconFacebookWhite from "@iconsV2/SocialIcon/facebook-white.svg?react";
import IconYoutubeWhite from "@iconsV2/SocialIcon/youtube-white.svg?react";
import IconLinkedInWhite from "@iconsV2/SocialIcon/linkedin-white.svg?react";
import AmamosCuidarte from "@iconsV2/amamos-cuidarte.svg?react";
import IconMessageMore from "@iconsV2/Chat/message-more.svg?react";

import {
  StyledLabel,
  StyledLink,
  StyledText,
  StyledTitle,
  StyledWrapperCity,
  StyledWrapperFooter,
  StyledWrapperInfo,
  StyledWrapperInfoAddress,
  StyledWrapperInfoNewLetter,
  StyledWrapperLinks,
  StyledWrapperWhatsApp,
} from "./styles";
import Analytics from "@/utils/Analytics";
import Paragraph from "@/components/Atoms/Paragraph";

const LOCATION = [
  {
    city: "Barranquilla",
    address: "Cra 51 # 82-223",
    to: "https://maps.app.goo.gl/rqZ911Gd6M9LBRLYA",
    hour: "Lun - Sab 8 am - 8 pm | Dom  10 am - 7 pm",
  },
  {
    city: "Bogotá",
    address: "Calle 93a #11-46",
    to: "https://maps.app.goo.gl/dbjBn4ZmpyAx2tv77",
    hour: "Lun - Sab 8 am - 8 pm | Dom  10 am - 7 pm",
  },
  // {
  //   city: "Medellín",
  //   // address: "Cra. 30a #9-71 a 9-1",
  //   to: "https://maps.app.goo.gl/QsZfWufTSZ5vPWAg7",
  //   hour: "Lun - Sab 8 am - 8 pm | Dom  10 am - 7 pm",
  // },
];

const WHATSAPP_INFO = {
  hour: "Lun - Vie 8 am - 8 pm | Sáb y Dom  10 am / 6 pm",
};

const SOCIAL_NETWORK = [
  { name: "Instagram", url: "https://www.instagram.com/fithub_co/", Icon: IconInstagramWhite },
  { name: "Facebook", url: "https://www.facebook.com/FitHubco", Icon: IconFacebookWhite },
  {
    name: "Youtube",
    url: "https://www.youtube.com/channel/UCCVbPuguSB8X7CU7jZdgsoQ",
    Icon: IconYoutubeWhite,
  },
  { name: "LinkedIn", url: "https://www.linkedin.com/company/fithub-colombia", Icon: IconLinkedInWhite },
];

const isOpen = () => {
  const now = dayjs().tz("America/Bogota");
  const dayOfWeek = now.day(); // 0 (Sunday) to 6 (Saturday)
  const currentTime = now.format("HH:mm");

  // Horarios de lunes a sábado
  const weekdayOpenTime = "08:00";
  const weekdayCloseTime = "20:00";

  // Horarios de domingo
  const sundayOpenTime = "09:00";
  const sundayCloseTime = "19:00";

  if (dayOfWeek >= 1 && dayOfWeek <= 6) {
    // Lunes a sábado
    return currentTime >= weekdayOpenTime && currentTime <= weekdayCloseTime;
  } else if (dayOfWeek === 0) {
    // Domingo
    return currentTime >= sundayOpenTime && currentTime <= sundayCloseTime;
  }

  return false;
};

const Footer = () => {
  const open = useMemo(() => {
    return isOpen();
  }, []);

  return (
    <>
      <StyledWrapperFooter id="footer">
        <div>
          <StyledWrapperInfo>
            <StyledWrapperInfoAddress>
              <StyledWrapperCity>
                <StyledTitle type="h6">Encuéntranos en</StyledTitle>

                {LOCATION.map((location) => {
                  return (
                    <div key={location.city}>
                      <div>
                        <Chip
                          tw="mr-2"
                          variant="rounded"
                          label={open ? "Abierto" : "Cerrado"}
                          bgColor={open ? tw`bg-tertiary-99` : tw`bg-error-99`}
                          textColor={open ? tw`text-tertiary-60` : tw`text-error-60`}
                        />
                        <StyledLabel sizeVariant="md">{location.city}</StyledLabel>
                      </div>

                      <Link
                        to={location.to}
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label={`Ubicación ${location.city} en Google Maps ${location.address}`}
                        title={`Ubicación ${location.city} en Google Maps ${location.address}`}
                      >
                        <IconPinLocation />
                        <StyledText sizeVariant="sm">{location.address}</StyledText>
                      </Link>
                      <div>
                        <IconClock />
                        <StyledText sizeVariant="sm">{location.hour}</StyledText>
                      </div>
                    </div>
                  );
                })}
              </StyledWrapperCity>

              <StyledWrapperWhatsApp>
                <StyledTitle type="h6">¿Necesitas ayuda? Habla con nosotros</StyledTitle>

                <div>
                  <Button
                    sizeVariant="md"
                    rel="noopener noreferrer"
                    target="_blank"
                    color="secondary"
                    variant="outlined"
                    // as={Link}
                    // to={
                    //   "https://api.whatsapp.com/send?phone=573173667567&text=Hola%20fithub,%20necesito%20ayuda"
                    // }
                    startIcon={<IconMessageMore />}
                    onClick={() => {
                      // @ts-expect-error Property '$crisp' does not exist on type 'Window & typeof globalThis'.
                      window.$crisp.push(["do", "chat:open"]);
                      Analytics.CustomerCareMessage({ channel: "Crisp" });
                      // window.$crisp.push([ "set", "message:text", [ "Hola, me puedes apy" ] ]);
                    }}
                  >
                    Chatea con nosotros
                  </Button>

                  <div>
                    <IconClock />
                    <StyledText sizeVariant="md">{WHATSAPP_INFO.hour}</StyledText>
                  </div>
                </div>
              </StyledWrapperWhatsApp>
            </StyledWrapperInfoAddress>

            <StyledWrapperInfoNewLetter>
              <AmamosCuidarte />

              <div className="link">
                <Button
                  color="secondary"
                  variant="text"
                  sizeVariant="md"
                  as={Link}
                  to={"https://blog.fithub.com.co"}
                  target="_blank"
                  rel="noopener noreferrer"
                  title={`Blog de Fithub`}
                  aria-label="Blog"
                >
                  Blog
                </Button>

                <Button
                  color="secondary"
                  variant="text"
                  sizeVariant="md"
                  as={Link}
                  to={"https://leads-fithub.subrik.com"}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Vende con nosotros"
                  title="Vende con nosotros"
                >
                  Vende con nosotros
                </Button>
              </div>
              <div className="form">
                <NewLetter />
              </div>

              <div className="backed">
                <IconCombinator />
                <IconIndustria />
              </div>
            </StyledWrapperInfoNewLetter>
          </StyledWrapperInfo>

          <StyledWrapperLinks>
            <div className="flag-logo">
              <IconBrandWhite />
              <IconFlagColombia />
            </div>
            <div className="links">
              <StyledText sizeVariant="sm">© {new Date().getFullYear()} fithub</StyledText>

              <StyledLink
                rel="noopener noreferrer"
                target="_blank"
                to={"/terms"}
                aria-label="Terminos y condiciones"
                title="Terminos y condiciones"
              >
                <Paragraph sizeVariant="sm">Términos y condiciones</Paragraph>
              </StyledLink>

              <StyledLink
                rel="noopener noreferrer"
                target="_blank"
                to={"/policy"}
                aria-label="Politicas de privacidad"
                title="Politicas de privacidad"
              >
                <Paragraph sizeVariant="sm">Políticas de privacidad</Paragraph>
              </StyledLink>
            </div>
            <div className="social-network">
              {SOCIAL_NETWORK.map(({ name, url, Icon }) => {
                return (
                  <Link key={name} rel="noopener noreferrer" target="_blank" title={name} to={url}>
                    <Icon />
                  </Link>
                );
              })}
            </div>
          </StyledWrapperLinks>
        </div>
      </StyledWrapperFooter>
    </>
  );
};

export default Footer;
