import { Suspense } from "react";
import { Outlet, ScrollRestoration } from "react-router-dom";
import { ToastContainer } from "react-toastify";

// TODO: Import the ConfirmationModal component from "@/components/Modal/ConfimationModal"
// this component soon deprecate
import { ConfirmationModal } from "@/components/Modal/ConfimationModal";
import AuthStepsModal from "@organisms/AuthStepsModal";
import useRootLayout from "./useRootLayout";
import ModalInformativeChangeLocation from "@/components/Molecules/ModalInformartiveChangeLocation";
import ModalAddressForm from "@/components/Organisms/ModalAddressForm";
import ModalGetTokenPlus from "@/components/Molecules/ModalGetTokenPlus";

const RootLayout = () => {
  const {
    isAuthModalOpen,
    handleCloseModalAuth,
    isOpenModalInformativeChangeLocation,
    handleCloseModalInformativeChangeLocation,
    isAddressModalOpen,
    setIsAddressModalOpen,
  } = useRootLayout();

  return (
    <>
      <Suspense fallback="">
        {isAuthModalOpen && (
          <AuthStepsModal isOpen={isAuthModalOpen} dismissable onModalClose={handleCloseModalAuth} />
        )}

        <ConfirmationModal />

        {isAddressModalOpen && (
          <ModalAddressForm isOpen={isAddressModalOpen} toggleAddressModal={setIsAddressModalOpen} />
        )}
      </Suspense>

      {isOpenModalInformativeChangeLocation && (
        <ModalInformativeChangeLocation
          open={isOpenModalInformativeChangeLocation}
          onClose={handleCloseModalInformativeChangeLocation}
        />
      )}

      <ScrollRestoration />
      <ToastContainer />
      <Outlet />
      <ModalGetTokenPlus />
    </>
  );
};

export default RootLayout;
