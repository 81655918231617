import tw, { css, globalStyles } from "twin.macro";
import { createGlobalStyle } from "styled-components";

import "./font.css";

import "react-lazy-load-image-component/src/effects/blur.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "react-toastify/dist/ReactToastify.css";

import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import "tippy.js/dist/svg-arrow.css";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/a11y";
import "swiper/css/grid";

const shake = css`
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-2px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(2px, 0, 0);
  }
` as unknown as string;

const GlobalStyles = createGlobalStyle({
  ...globalStyles,
  "*": {
    "&::-webkit-scrollbar": tw`w-1`,
    "&::-webkit-scrollbar-track": tw`rounded`,
    "&::-webkit-scrollbar-thumb": tw`bg-primaryV2-60 rounded`,
  },
  body: tw`bg-white text-gray-400 font-secondary antialiased m-0 p-0`,

  ".Toastify__toast-body": tw`font-secondary font-bold text-sm`,

  "*:focus": {
    outline: "none",
  },

  "[data-tippy-root]": {
    zIndex: "29 !important",
  },

  ".slider-checkbox": {
    position: "relative",
    marginTop: 12,
    marginBottom: 12,
    input: {
      margin: 0,
      marginTop: 1,
      cursor: "pointer",
      opacity: 0,
      "-ms-filter": "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)",
      filter: "alpha(opacity=0)",
      "-moz-opacity": 0,
      "-khtml-opacity": 0,
      position: "absolute",
      zIndex: 1,
      top: 0,
      left: 0,
      background: "red",
      width: 40,
      height: 20,
      "&:checked + .label": {
        "&:before": {
          backgroundColor: "$checked-color",
          content: "\f00c",
          paddingLeft: 6,
        },
        "&:after": {
          left: 21,
        },
      },
    },
    ".label": {
      position: "relative",
      paddingLeft: 46,
      "&:before, &:after": {
        position: "absolute",
        "-webkit-border-radius": 10,
        "-moz-border-radius": 10,
        "border-radius": 10,
        transition: "background-color 0.3s, left 0.3s",
      },
      "&:before": {
        content: "\f00d",
        color: "#fff",
        boxSizing: "border-box",
        fontFamily: "'FontAwesome', sans-serif",
        paddingLeft: 23,
        fontSize: 12,
        lineHeight: 20,
        backgroundColor: "$unchecked-color",
        left: 0,
        top: 0,
        height: 20,
        width: 40,
        "-webkit-border-radius": 10,
        "-moz-border-radius": 10,
        borderRadius: 10,
      },
      "&:after": {
        content: "",
        letterSpacing: 20,
        background: "#fff",
        left: 1,
        top: 1,
        height: 18,
        width: 18,
      },
    },
    "input:checked, input:not(:checked)": {
      '& + .label[for="c1"]:before': {
        backgroundColor: "#c21717",
      },
    },
    'input:checked + .label[for="c2"]:before': {
      backgroundColor: "#ffdc00",
    },
    'input:not(:checked) + .label[for="c3"]:before': {
      backgroundColor: "#0a4776",
    },
  },
  "div.shake": {
    animation: `${shake} 1s infinite`,
  },

  ".tippy-box": tw`bg-white text-black shadow-lg`,
  ".tippy-svg-arrow": tw`[&>svg>path]:fill-gray-200`,
  ".grecaptcha-badge": tw`invisible`,

  ".no-scroll": {
    overflow: "hidden",
    height: "100vh",
  },
});

export default GlobalStyles;
