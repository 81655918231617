import { type AuthStep } from "@/components/Navbar/AuthModal/types";
import { type CALLBACK_MODAL_ADDRESS } from "@/hooks/useAddressModal";
import { type CALLBACK_MODAL_AUTH } from "@/hooks/useModalAuth";
import { type CALLBACK_MODAL_TOKEN_PLUS } from "@/Pages/UpdatePhone";
import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

export interface IGenerics {
  modalAuth: {
    openModalAuth: boolean;
    callbackIdentifier: CALLBACK_MODAL_AUTH | undefined;
    initialStep?: AuthStep;
  };
  modalAddress: {
    openModalAddress: boolean;
    callbackIdentifier: CALLBACK_MODAL_ADDRESS | undefined;
  };
  scriptGoogleMaps: {
    isLoaded: boolean;
    loadError: Error | undefined;
  };
  modalTokenPlus: {
    openModalTokenPlus: boolean;
    callbackIdentifier: CALLBACK_MODAL_TOKEN_PLUS | undefined;
  };
}

const initialState: IGenerics = {
  modalAuth: {
    openModalAuth: false,
    callbackIdentifier: undefined,
    initialStep: "login",
  },
  modalAddress: {
    openModalAddress: false,
    callbackIdentifier: undefined,
  },
  scriptGoogleMaps: {
    isLoaded: false,
    loadError: undefined,
  },
  modalTokenPlus: {
    openModalTokenPlus: false,
    callbackIdentifier: undefined,
  },
};

export const genericsSlice = createSlice({
  name: "generics",
  initialState,
  reducers: {
    setOpenModalAuth: (
      state,
      action: PayloadAction<{
        open: boolean;
        callbackIdentifier?: CALLBACK_MODAL_AUTH | undefined;
        initialStep?: AuthStep;
      }>
    ) => {
      state.modalAuth.openModalAuth = action.payload.open;
      state.modalAuth.callbackIdentifier = action.payload.callbackIdentifier ?? undefined;
      state.modalAuth.initialStep = action.payload.initialStep ?? state.modalAuth.initialStep;
    },
    setCallbackAuthIdentifier: (state, action: PayloadAction<CALLBACK_MODAL_AUTH | undefined>) => {
      state.modalAuth.callbackIdentifier = action.payload ?? undefined;
    },
    setCallbackAddressIdentifier: (state, action: PayloadAction<CALLBACK_MODAL_ADDRESS | undefined>) => {
      state.modalAddress.callbackIdentifier = action.payload ?? undefined;
    },
    setCallbackTokenPlusIdentifier: (state, action: PayloadAction<CALLBACK_MODAL_TOKEN_PLUS | undefined>) => {
      state.modalTokenPlus.callbackIdentifier = action.payload ?? undefined;
    },
    setOpenModalAddress: (
      state,
      action: PayloadAction<{ open: boolean; callbackIdentifier?: CALLBACK_MODAL_ADDRESS }>
    ) => {
      state.modalAddress.openModalAddress = action.payload.open;
      state.modalAddress.callbackIdentifier = action.payload.callbackIdentifier;
    },
    setIsLoadedScriptGoogleMaps: (
      state,
      action: PayloadAction<{ isLoaded?: boolean; loadError?: Error }>
    ) => {
      if (action.payload.isLoaded) {
        state.scriptGoogleMaps.isLoaded = action.payload.isLoaded;
      }
      if (action.payload.loadError) {
        state.scriptGoogleMaps.loadError = action.payload.loadError;
      }
    },
    setOpenModalTokenPlus: (
      state,
      action: PayloadAction<{ open: boolean; callbackIdentifier?: CALLBACK_MODAL_TOKEN_PLUS }>
    ) => {
      state.modalTokenPlus.openModalTokenPlus = action.payload.open;
      state.modalTokenPlus.callbackIdentifier = action.payload.callbackIdentifier ?? undefined;
    },
  },
});

export const genericsAction = genericsSlice.actions;
