import { useState } from "react";
import IconGiftPresent from "@iconsV2/Items/gift-present.svg?react";
import IconBag03 from "@iconsV2/Interrface/bag-03.svg?react";
import IconVoucher from "@iconsV2/Interrface/voucher.svg?react";
import IconCalendarClock from "@iconsV2/Calendar/clock.svg?react";
import IconFileFilled from "@iconsV2/Documents/file-filled.svg?react";
import useCredits from "@/hooks/useCredits";

const menuData = [
  {
    Icon: IconGiftPresent,
    title: "Canjear regalo",
    to: "/gift-cards/redeem",
    external: false,
  },
  {
    Icon: IconBag03,
    title: "Comprar de nuevo",
    to: "/orders?filter-by=completed",
    external: false,
  },
  {
    Icon: IconVoucher,
    title: "Refiere y gana $10k",
    to: "/referral",
    external: false,
  },
  {
    Icon: IconCalendarClock,
    title: "Historial de pedidos",
    to: "/orders",
    external: false,
  },
  {
    Icon: IconFileFilled,
    title: "Blog",
    to: "https://blog.fithub.com.co/",
    external: true,
  },
];

export default function useProfileMenu({ handleOpenMenu }: { handleOpenMenu: (value: boolean) => void }) {
  const [logoutModalOpen, setLogoutModalOpen] = useState(false);

  const { creditsAvailable } = useCredits({ creditsAvailable: true });

  function closeLogoutModal() {
    setLogoutModalOpen(false);
  }

  function openLogoutModal() {
    handleOpenMenu(false);
    setLogoutModalOpen(true);
  }

  return {
    // data
    logoutModalOpen,
    menuData,
    creditsAvailable,

    // funcs
    closeLogoutModal,
    openLogoutModal,
  };
}
